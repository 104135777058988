import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { css } from "@emotion/core"
import Form from "../components/form"

const ProductTemplate = (props) => {
    const product = props.data.product
    const options = props.data.options.edges
    // const sku = props.pageContext.id

    console.log("options", options)

    return (
        <Layout>
            <SEO title={product.name} />

            <div className="container">

                <div css={hero}>
                    <div style={{
                        backgroundColor: `#${product.color || 'F9F9F9'}`
                    }}>
                        <Img fluid={product.logo.childImageSharp.fluid}  />
                    </div>
                    <div css={heroMain}>
                        <h1>{product.name}</h1>
                        <p>{product.desc}</p>
                    </div>
                </div>

                {product.content &&
                    <section 
                        dangerouslySetInnerHTML={{ __html: product.content }}
                        css={postHtml}
                    />
                }

                {product.included && 
                    <section css={includedItems}>
                        <h2>Sont inclus dans le {product.name}</h2>
                        {product.included.map( (item, index) => (
                            <div key={item.title}>
                                <h3>{index + 1}. {item.title}</h3>
                                <p>{item.desc}</p>
                            </div>
                        ))}
                    </section>
                }

                <section>
                    <h2>Les options disponibles</h2>
                    <div css={optionsGrid}>
                    {options.map( ({node: option }) => (
                            <div key={option.id}>
                                <h3>{option.name}</h3>
                                <p>{option.desc}</p>
                            </div>
                    ))}
                    </div>
                </section>

                <section css={cantonsSection}>
                    {[
                        {canton: "Canton de Vaud", city: "Lausanne"},
                        {canton: "Canton de Genève", city: "Genève"},
                        {canton: "Canton du Jura", city: "Delémont"},
                        {canton: "Canton du Neuchâtel", city: "Neuchâtel"},
                        {canton: "Canton de Vaud", city: "Sion"},
                        {canton: "Canton du Fribourg", city: "Fribourg"},
                        {canton: "Canton du Berne", city: "Berne"},
                    ].map( (place, index) => (
                        <div key={place.city}>
                            <h2>{product.name} à {place.city}, {place.canton}</h2>
                            <p>{["Votre entreprise est basée", "Votre entreprise est active", "Vous êtes une entreprise ou association"][Math.floor((index+1)/3)]} dans le {place.canton} et vous {["souhaitez réaliser", "comptez faire", "avez besoin d'organiser"][Math.floor((index+1)/3)]} un {product.name} ? Nous réalisons votre {product.name} dans la région de {place.city}. {["N'hésitez pas à nous contacter", "Contactez-nous", "Demandez-nous une offre"][Math.floor((index+1)/3)]} !</p>
                        </div>
                    ))}
                </section>

                <Form 
                    formName={product.id}
                />

            </div>
        </Layout>
    )
}

export default ProductTemplate

const hero = css`
    margin: 50px 0;
    // background-color: #F9F9F9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
    overflow: hidden;

    .gatsby-image-wrapper {
        width: 100px;
        margin-left: 20px;
    }
`

const heroMain = css`
    padding: 20px 30px 30px;

    h1 {
        font-size: 50px;
        margin-top: 0;
    }

    p {
        margin-bottom: 0;
    }
`

const optionsGrid = css`
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr;
    row-gap: 20px;
    
    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    div {
        border: 1px solid #F9F9F9;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
        padding: 20px;

        h3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 18px;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
        }
    }
`

const includedItems = css`
    padding: 20px 20px 10px;
    margin: 100px 0;
    background-color: #F9F9F9;

    h3 {
        font-family: 'Open Sans', sans-serif;
    }
`

const postHtml = css`
    h2 {
        font-size: 24px;
        font-family: 'Open Sans', sans-serif;
    }
`

const cantonsSection = css`
    margin: 100px 0;
`

export const productTemplateQuery = graphql`
    query productTemplateQuery ($id: String!) {
        product: productsYaml(id: {eq: $id}) {
            id
            name
            desc
            color
            logo {
                childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
            }
            content
            included {
                title
                desc
            }
        }
        options: allOptionsYaml {
            edges {
              node {
                id
                name
                desc
              }
            }
          }
    }
`